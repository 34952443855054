// import './src/styles/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/responsive.scss';
import './src/pages/styles.scss';
import './src/styles/typography.scss';

import React from 'react';
import HelmetTitle from './src/components/helmet';

export const wrapPageElement = ({element}) => {
  return (
    <>
      <HelmetTitle />
      {element}
    </>
  );
};
