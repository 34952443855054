import React, {FC} from 'react';
import {Helmet} from 'react-helmet';
import {Location} from '@reach/router';
import {HelmetHeaderProps} from '../types';

export default ({}: FC<HelmetHeaderProps>) => {
  const headerData = [
    // {
    //   title: 'Customer support contacts | Hexact',
    //   description: 'Customer support contacts - Making sense of data for actionable insights',
    //   path: '/contact',
    // },
    {
      title: 'Cookie Policy | Hexact',
      description: 'This Cookie Policy describes how and why HEXACT use cookies',
      path: '/cookie-policy',
    },
    {
      title: 'Privacy Policy | Hexact',
      description:
        'Your privacy is very important to us and we have created this privacy statement in order to demonstrate our commitment to you.',
      path: '/privacy-policy',
    },
    {
      title: 'Terms of Service | Hexact',
      description:
        'These Terms of Service contain an Arbitration Agreement that may impact your rights to use the courts to resolve disputes.',
      path: '/terms-of-use',
    },
    {
      title: '',
      description: '',
      path: '/home',
    },
    {
      title: '',
      description: '',
      path: '/404',
    },
    {
      title: 'No-code Point-and-click Business Intelligence Solutions | Hexact, Inc.',
      description:
        'Data mining, analysis & machine learning solutions for business. Experience the true power of the cloud + AI to delegate and scale your work.',
      path: '/',
    },

    {
      title: 'Join our Team | Hexact Careers',
      description:
        'We have a multinational team and this is how we foster inclusion. We need your skills and a positive attitude to fulfill our mission of changing the world for the better.',
      path: '/careers',
    },

    {
      title: 'Customer Success Specialist Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as Customer Success Specialist',
      path: '/careers/customer-success-specialist',
    },

    {
      title: 'DevOps Engineer Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as DevOps Engineer',
      path: '/careers/devops',
    },

    {
      title: 'Go (Golang, Google Go) Developer Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as Go (Golang, Google Go) Developer',
      path: '/careers/golang',
    },

    {
      title: 'BackEnd (Node.js) Developer Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as BackEnd (Node.js) Developer',
      path: '/careers/nodejs',
    },

    {
      title: 'FrontEnd (React.js) Developer Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as FrontEnd (React.js) Developer',
      path: '/careers/reactjs',
    },

    {
      title: 'Senior Software Engineer Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as Senior Software Engineer',
      path: '/careers/senior-software-engineer',
    },

    {
      title: 'SEO Strategist Vacancy at Hexact',
      description: 'Great opportunity to join our fast growing team as SEO Strategist',
      path: '/careers/seo-strategist',
    },
    {
      title: 'Leadership | About us | Hexact',
      description: "Meet the Hexact's leadership! Leading today to build tomorrow’s leaders at Hexact.",
      path: '/leadership',
    },

    {
      title: 'For Investors | Invest in The No-code movement | Hexact',
      description:
        'We invite investors who share our vision to join us along the way and become part of our success at our no-code automation movement in Hexact.',
      path: '/for-investors',
    },

    {
      title: 'Hexact AI Products | Hexact Ecosystem',
      description:
        'The automation revolution has started. Tap into the true power of the cloud with scalable business solutions designed for businesses of all sizes.',
      path: '/products',
    },
    {
      title: 'Content Writer and Editor (English)',
      description: 'Great opportunity to join our fast growing team as Content Writer and Editor (English)',
      path: '/careers/content-writer-and-editor',
    },
    {
      title: 'Sales Development Representative (SDR) (English)',
      description: 'Great opportunity to join our fast growing team as Sales Development Representative',
      path: '/careers/sales-development-representative',
    },
    // {
    //   title: 'Startengine Campaign | Hexact',
    //   description: "Invest in Hexact's workflow automation movement and become a part of our time-saving, no-code web scraping journey!",
    //   path: '/startengine',
    // },
  ];

  return (
    <Location>
      {({location}) => {
        const item = headerData.find((data: any) => {
          if (location.pathname[location.pathname.length - 1] === '/' && location.pathname !== '/') {
            return data.path === location.pathname.substr(0, location.pathname.length - 1);
          } else if (location.pathname === '/') {
            return data.path === location.pathname;
          } else if (data.path === '/get-our-growth-hacking-playbook' && location.pathname.includes(data.path)) {
            return data;
          } else {
            return location.pathname === data.path || location.pathname === data.path + '/';
          }
        });
        return (
          item && (
            <Helmet key={item.title} title={item.title}>
              <html lang="en" />
              {location.pathname === '/' && (
                <script type="application/ld+json">
                  {`{
                      "@context": "https://schema.org",
                      "@type": "Organization",
                      "name": "Hexact",
                      "alternateName": "Hexact Inc",
                      "url": "https://hexact.io/",
                      "logo": "https://hexact.io/img/hexact-logo.svg",
                      "sameAs": [
                        "https://www.linkedin.com/company/hexactinc",
                        "https://www.youtube.com/c/HexactInc",
                        "https://www.instagram.com/hexacthq/",
                        "https://www.facebook.com/hexactinc/",
                        "https://twitter.com/hexactinc"
                      ]
                    }`}
                </script>
              )}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#03b8ff" /> */}
              <link rel="canonical" href={`https://hexact.io${location.pathname}`} />
              <meta name="msapplication-TileColor" content="#03b8ff" />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <meta name="msapplication-config" content="/browserconfig.xml" />

              <meta name="description" content={item.description} />
              <meta
                property="og:image"
                content={
                  location.pathname.includes('/careers')
                    ? 'https://hexact.io/careers.png'
                    : location.pathname.includes('/startengine')
                    ? 'https://hexact.io/startengine.jpg'
                    : 'https://hexact.io/Hexact.png'
                }
              />
              <meta property="og:url" content={`https://hexact.io${location.pathname}`} />
              <meta property="og:title" content={item.title} />
              <meta property="og:description" content={item.description} />
              <meta property="og:type" content={'website'} />
              <meta property="og:locale" content={'en_US'} />
              <meta
                name="twitter:image"
                content={
                  location.pathname.includes('/careers')
                    ? 'https://hexact.io/careers.png'
                    : location.pathname.includes('/startengine')
                    ? 'https://hexact.io/startengine.jpg'
                    : 'https://hexact.io/Hexact.png'
                }
              />
              <meta name="twitter:title" content={item.title} />
              <meta name="twitter:description" content={item.description} />
              <meta name="twitter:card" content={'summary_large_image'} />
              <meta name="twitter:site" content={'@hexactinc'} />
            </Helmet>
          )
        );
      }}
    </Location>
  );
};
